import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {cfg} from "../../../cfg";
import FetchData, {Fetching} from "../../api/FetchData";
import { format } from "date-fns";
import GetOrdersApi from "../../api/orders/GetOrdersApi";

const AddOrder = (props) => {
    return (
        <>
            <Formik
                initialValues={{
                    long_or_short: 0,
                    currency: 'BTCUSDT',
                    count_coins: 0.01,
                    cross: 1,
                    datebook: '',
                }}
                validate={values => {
                    const errors = {};

                    if (!values.datebook)            errors.datebook      = 'Обязательное поле.';
                    if (!values.currency)            errors.currency      = 'Обязательное поле.';
                    if (values.cross === '')         errors.cross         = 'Обязательное поле.';
                    if (values.count_coins === '')   errors.count_coins   = 'Обязательное поле.';
                    if (values.long_or_short === '') errors.long_or_short = 'Обязательное поле.';
                    if (values.datebook !== '' && new Date() < Date.parse(values.datebook)) {
                        errors.datebook = 'Дата не может быть позднее сегодняшнего дня.';
                    }

                    return errors;
                }}
                onSubmit={(values, {setSubmitting}) => {
                    props.setLogs('Добавляем...');
                    props.setOrderList(<h6 className="text-center">Загрузка...</h6>);
                    document.querySelector(`#${cfg.ADD_ORDER_MODAL_ID} .btn-close`).click();

                    setSubmitting(false);
                    values.cross         *= 1;
                    values.count_coins   *= 1;
                    values.long_or_short *= 1;
                    values.datebook = format(values.datebook, "yyyy-MM-dd hh:mm"); // example: 2024-26-12 03:00
                    Fetching({
                        method:  "POST",
                        request: props.isBot ? cfg.addOrderBot : cfg.addOrder,
                        values:  values,
                    }).then(r => {
                        r.status === true
                            ? props.setLogs(`Ордер ${props.isBot ? 'бота' : 'админа' } успешно добавлен`)
                            : props.setLogs(r.message ?? 'Ошибка, что-то пошло не так.');

                        setTimeout(() => {
                            document.querySelector('button[name="updateList"]').click();
                            // props.setOrderList(<GetOrdersApi/>);
                        }, 400);
                    });
                }}
            >
                {({isSubmitting}) => (
                    <Form className="d-flex flex-column">
                        {props.title &&
                            <h4>{props.title}</h4>
                        }

                        <ErrorMessage name="currency" component="div"/>
                        <div className="form-floating mt-3">
                            <Field className="form-control" id="currency" type="text" name="currency"/>
                            <label htmlFor="currency">Валюта:</label>
                        </div>

                        <ErrorMessage name="count_coins" component="div"/>
                        <div className="form-floating mt-3">
                            <Field className="form-control" id="count_coins" type="number" min="0.01" step="0.01" name="count_coins"/>
                            <label htmlFor="count_coins">Кол-во монет:</label>
                        </div>

                        <ErrorMessage name="datebook" component="div"/>
                        <div className="form-floating mt-3">
                            <Field className="form-control" id="datebook" type="datetime-local" name="datebook"/>
                            <label htmlFor="datebook">Дата и время:</label>
                        </div>

                        <ErrorMessage name="long_or_short" component="div"/>
                        <div className="form-floating mt-3">

                            <Field className="form-control" id="long_or_short" name="long_or_short" as="select">
                                <option disabled value="" label="Выберите длинну"/>
                                <option value="0">long</option>
                                <option value="1">short</option>
                            </Field>
                            <label htmlFor="long_or_short">Long/short:</label>
                        </div>

                        <ErrorMessage name="cross" component="div"/>
                        <div className="form-floating mt-3">
                            <Field className="form-control" id="cross" type="number" min="1" step="1" name="cross"/>
                            <label htmlFor="cross">Кросс:</label>
                        </div>

                        <button className="btn btn-primary w-50 mt-3 py-2" type="submit" disabled={isSubmitting}>
                            Отправить
                        </button>

                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddOrder;