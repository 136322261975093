import React, {useEffect, useState} from 'react';
import imgSort from '../../../img/sort.svg'
import imgUpdate from '../../../img/update.svg'
import GetOrdersBotApi from "../../api/orders/GetOrdersBotApi";
import {cfg} from "../../../cfg";
import { Form, Button } from 'react-bootstrap';
import FetchData, {Fetching} from "../../api/FetchData";

const GetOrdersBot = (props) => {
    const [balance, setBalance] = useState();
    const [newBalance, setNewBalance] = useState();

    useEffect(() => {
        handleGetBalance().then();
        setInterval(() => {
            handleGetBalance().then();
        }, 5000)
    }, [])

    async function handleGetBalance() {
        Fetching({
            debug: false,
            method: 'POST',
            values: {user_id : 2},
            request: cfg.getBalance,
        })
        .then(r => {
            let curBalance = r.payload[0] ?? 0;
            setBalance(curBalance.toFixed(2));
            if (!r.status) props.setLogs('Баланс бота: ' + r.message ?? 'Ошибка получения баланса');
        });
    }

    async function handleSetBalance() {
        if (!newBalance) {
            props.setLogs('Введите новый баланс');
            return false;
        }

        Fetching({
            debug: false,
            method: 'POST',
            request: cfg.editBalance,
            values: {
                user_id : 2,
                new_balance : newBalance,
            },
        })
            .then(r => {
                r.status === true
                    ? props.setLogs('Баланс бота успешно изменен')
                    : props.setLogs(r.message ?? 'Ошибка, что-то пошло не так.');

                setTimeout(() => {
                    handleGetBalance().then();
                }, 200);
            });
    }

    return (
        <>
            <Form className="mb-2 row"
                  onSubmit={(event) => {
                      event.preventDefault();
                      handleSetBalance().then();
                  }}
            >
                <Form.Group controlId="formStep1">
                    <h5><Form.Label>Баланс: {balance}</Form.Label></h5>
                    <div className="d-flex">
                        <Form.Control
                            style={{maxWidth: '150px'}}
                            className="me-2"
                            type="number"
                            name="balance"
                            placeholder="Новый баланс"
                            onChange={e => setNewBalance(e.target.value)}
                        />
                        <Button variant="primary" type="submit">
                            Сохранить
                        </Button>
                        <Button
                            className="ms-2 d-flex align-items-center"
                            variant="primary"
                            type="button"
                            onClick={() => {
                                handleGetBalance().then(r => {
                                    props.setLogs('Отображен актуальный баланс бота.');
                                });
                            }}
                        >
                            <img className="mt-1" src={imgUpdate} alt=""/>
                        </Button>
                    </div>
                </Form.Group>
            </Form>

            <div className="d-flex justify-content-between align-items-center">
                <h4 className="">Orders</h4>
                <div className="d-flex">
                    <button type="button"
                            className="btn btn-primary p-2 me-2 d-md-none"
                            data-bs-toggle="modal"
                            data-bs-target={'#' + cfg.ADD_ORDER_MODAL_ID}
                    >
                        Добавить ордер
                    </button>
                    <button name="updateList" className="btn btn-primary py-2 me-2 d-flex align-items-center"
                        onClick={(e) => {
                            props.setOrderList(<h6 className="text-center">Загрузка...</h6>);
                            setTimeout(() => {
                                props.setOrderList(<GetOrdersBotApi setLogs={props.setLogs}/>);
                            }, 500);
                        }}
                    >
                        <span className="me-2 d-none d-md-block">Обновить список</span>
                        <img className="mt-1" src={imgUpdate} alt=""/>
                    </button>
                    {/*<button name="toggleList" sort-by="DESC" id="sortTaskList" className="btn btn-primary py-2 me-2">*/}
                    {/*    <img src={imgSort} alt=""/>*/}
                    {/*</button>*/}
                </div>
            </div>
            <hr/>
            {props.orderList}
        </>
    );
}

export default GetOrdersBot;