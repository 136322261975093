import React, {useEffect, useState} from 'react';
import {format} from "date-fns";
import {cfg} from "../../../cfg";
import {Fetching} from "../../api/FetchData";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {de} from "date-fns/locale";

const EditOrder = ({editOrder, setLogs, setIsLoading, isBot = false}) => {
    const [cross, set_cross]                 = useState(1);
    const [currency, set_currency]           = useState('');
    const [datebook, set_datebook]           = useState('');
    const [count_coins, set_count_coins]     = useState(0.01);
    const [long_or_short, set_long_or_short] = useState(0);

    useEffect(() => {
        if (!editOrder) return;

        set_long_or_short(editOrder.type_order ?? 0);
        set_currency(editOrder.curency ?? ''); // todo api опечатка
        set_count_coins(editOrder.sizeCoins ?? 0.01);
        set_cross(editOrder.cross ?? 1);
        set_datebook(editOrder.date_create ?? '');
    }, [editOrder]);

    return (
    <div className="modal fade" id={cfg.EDIT_ORDER_MODAL_ID} tabIndex="-1"
         aria-labelledby={cfg.EDIT_ORDER_MODAL_ID + '_Title'} style={{display: 'none'}} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">

                    <Formik
                        initialValues={{}} // require param
                        validate={() => {
                            const errors = {};

                            if (!datebook)            errors.datebook      = 'Обязательное поле.';
                            if (!currency)            errors.currency      = 'Обязательное поле.';
                            if (!cross)               errors.cross         = 'Обязательное поле.';
                            if (!count_coins)         errors.count_coins   = 'Обязательное поле.';
                            if (!long_or_short)       errors.long_or_short = 'Обязательное поле.';
                            if (datebook !== '' && new Date() < Date.parse(datebook)) {
                                errors.datebook = 'Дата не может быть позднее сегодняшнего дня.';
                            }

                            return errors;
                        }}
                        onSubmit={({}, {setSubmitting}) => {
                            setIsLoading(true);
                            setSubmitting(false);
                            setLogs(`Обновляем ордер #${editOrder.id}...`);
                            // document.querySelector('#order-list-table tbody')
                                // .innerHTML = cfg.LOADING_MSG;
                            document.querySelector(`#${cfg.EDIT_ORDER_MODAL_ID} .btn-close`).click();

                            let type_order;
                            switch (long_or_short) {
                                case 'long':
                                    type_order = 0;
                                    break;
                                case 'short':
                                    type_order = 1;
                                    break;
                                default:
                                    type_order = long_or_short * 1;
                                    break;
                            }

                            Fetching({
                                method:  "POST",
                                request: isBot ? cfg.editOrderBot : cfg.editOrder,
                                values: {
                                    order_id:      editOrder.id,
                                    long_or_short: type_order, // 0 or 1 - required
                                    currency:      currency,
                                    count_coins:   count_coins * 1,
                                    cross:         cross  * 1,
                                    datebook:      format(datebook, "yyyy-MM-dd hh:mm"), // example: 2024-26-12 03:00
                                },
                            })
                                .then(r => {
                                    r.status === true
                                        ? setLogs(`Ордер #${editOrder.id} ${isBot && 'бота'} успешно изменен`)
                                        : setLogs(r.message ?? 'Ошибка, что-то пошло не так.');

                                    setTimeout(() => {
                                        setIsLoading(false);
                                    }, 400);
                                })
                                .catch(err => {
                                    setIsLoading(false);
                                    setLogs('Ошибка:' + JSON.stringify(err));
                                });
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form className="d-flex flex-column modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title fs-5" id={cfg.EDIT_ORDER_MODAL_ID + '_Title'}>
                                        Изменить ордер {isBot ? 'бота' : 'админа'} #{editOrder.id ?? 'Не найден.'}
                                    </h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                                </div>

                                <div className="modal-body">
                                    <ErrorMessage name="currency" component="div"/>
                                    <div className="form-floating mt-3">
                                        <Field className="form-control" id="currency" type="text" name="currency"
                                               value={currency}
                                               onChange={(e) => {
                                                   set_currency(e.target.value);
                                               }}
                                        />
                                        <label htmlFor="currency">Валюта:</label>
                                    </div>

                                    <ErrorMessage name="count_coins" component="div"/>
                                    <div className="form-floating mt-3">
                                        <Field value={count_coins} className="form-control" id="count_coins" type="number" min="0" step="0.001" name="count_coins"
                                               onChange={(e) => {
                                                   set_count_coins(e.target.value);
                                               }}
                                        />
                                        <label htmlFor="count_coins">Кол-во монет:</label>
                                    </div>

                                    <ErrorMessage name="datebook" component="div"/>
                                    <div className="form-floating mt-3">
                                        <Field value={datebook} className="form-control" id="datebook" type="datetime-local" name="datebook"
                                               onChange={(e) => {
                                                   set_datebook(format(e.target.value, "yyyy-MM-dd hh:mm"));
                                               }}
                                        />
                                        <label htmlFor="datebook">Дата и время:</label>
                                    </div>

                                    <ErrorMessage name="long_or_short" component="div"/>
                                    <div className="form-floating mt-3">
                                        <select value={long_or_short} className="form-control" id="long_or_short" name="long_or_short"
                                                onChange={(e) => {
                                                    set_long_or_short(e.target.value);
                                                }}
                                        >
                                            <option disabled value="" label="Выберите длинну"/>
                                            <option value="0">long</option>
                                            <option value="1">short</option>
                                        </select>
                                        <label htmlFor="long_or_short">Long/Short:</label>
                                    </div>

                                    <ErrorMessage name="cross" component="div"/>
                                    <div className="form-floating mt-3">
                                        <Field value={cross} className="form-control" id="cross" type="number" min="1" step="1" name="cross"
                                               onChange={(e) => {
                                                   set_cross(e.target.value);
                                               }}
                                        />
                                        <label htmlFor="cross">Кросс:</label>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                        Close
                                    </button>
                                    <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                        Отправить
                                    </button>
                                </div>

                            </Form>
                        )}
                    </Formik>

        </div>
    </div>
    );
};

export default EditOrder;