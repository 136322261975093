import React, {useState} from 'react';
import AddOrder from "../components/ui/orders/AddOrder";
import GetOrders from "../components/ui/orders/GetOrders";
import {cfg} from "../cfg";
import GetOrdersApi from "../components/api/orders/GetOrdersApi";

const AdminUser = (props) => {
    const [logs, setLogs] = useState('logs');
    const [orderList, setOrderList] = useState(<GetOrdersApi setLogs={setLogs}/>);

    return (
        <div className="container-fluid">
            <div className="row" style={{minHeight: '100vh'}}>
                <div className="d-none d-md-block col-md-4 col-lg-3 bg-body-secondary p-3" style={{overflow: 'auto', maxHeight: '100vh'}}>
                    <AddOrder setOrderList={setOrderList} setLogs={setLogs} title='Добавить ордер'/>
                </div>
                <div className="col col-md-8 col-lg-9 bg-body-tertiary p-3">
                    <div className="row">
                        <div className="alert alert-info alert-dismissible fade show" role="alert"
                             style={{position: 'sticky', top: '20px', zIndex: 9}}
                        >
                            {logs}
                        </div>
                    </div>
                    <GetOrders orderList={orderList} setOrderList={setOrderList} setLogs={setLogs}/>
                </div>
            </div>

            {/* Modal - Add order */}
            <div className="modal fade" id={cfg.ADD_ORDER_MODAL_ID} tabIndex="-1" data-bs-backdrop="static"
                 aria-labelledby={cfg.ADD_ORDER_MODAL_ID + '_Title'} style={{display: 'none'}} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={cfg.ADD_ORDER_MODAL_ID + '_Title'}>Добавить новый ордер</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <AddOrder setOrderList={setOrderList} setLogs={setLogs}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminUser;