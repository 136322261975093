import React, {useEffect, useState} from 'react';
import {cfg} from "../../../cfg";
import FetchData, {Fetching} from "../FetchData";
import EditOrder from "../../ui/orders/EditOrder";

export default function GetOrdersBotApi(props) {
    const [editOrder, setEditOrder] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [tpslData, setTpslData] = useState({
        "id_order": null,
        "stop_loss":   0,
        "take_profit": 0
    });

    // let data = false;
    let data = FetchData({
        method: 'POST',
        values: {type_order : 'all'},
        request: cfg.getOrdersBot,
        debug: false,
    });

    if (isLoading) {
        data = FetchData({
            method: 'POST',
            values: {type_order : 'all'},
            request: cfg.getOrdersBot,
            debug: false,
            run: Math.random()
        });
    }

    if (isLoading || !data) return cfg.getLoadingMsg();
    if(['ok', 'success'].includes(data.status) || data.status === true) {
        let list = [];

        if (data.payload && Number(data.payload[0].id) < Number(data.payload[data.payload.length-1].id)) {
            data.payload = data.payload.reverse();
        }

        data.payload.map((e) => {
            list.push(
                <tr key={e.id}>
                    {/*<td><input type="checkbox"/></td>*/}
                    <td>
                        <div data-bs-toggle="modal"
                             data-bs-target={'#' + cfg.EDIT_ORDER_MODAL_ID}
                             onClick={() => {
                                 setEditOrder(e);
                             }}
                        >
                            <svg className="bi bi-pencil-square cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg>
                        </div>
                    </td>
                    <th scope="row">{e.id}</th>
                    <td>{(+e.pnl).toFixed(2)}</td>
                    <td>{e.roi.toFixed(2)}</td>
                    <td>{e.sizeCoins}</td>
                    <td>{e.type_order}</td>
                    <td>{e.marning_money.toFixed(2)}</td>
                    <td>{e.marning_ration}</td>
                    <td>{e.entry_price.toFixed(2)}</td>
                    <td>{e.mark_price.toFixed(2)}</td>
                    <td>{e.liq_price.toFixed(2)}</td>
                    <td>{e.curency}</td>
                    <td>{e.cross}</td>
                    <td>
                        <input className='stop_loss' style={{width: '100px'}} type="number" min={0}
                               defaultValue={e.stop_loss}
                               onChange={(elem) => {
                                   setTpslData({
                                       "id_order":    e.id,
                                       "stop_loss":   elem.target.value,
                                       "take_profit": tpslData.take_profit
                                   });
                               }}
                        />
                    </td>
                    <td>
                        <input className='take_profit' style={{width: '100px'}} type="number" min={0}
                               defaultValue={e.take_profit}
                               onChange={(elem) => {
                                   setTpslData({
                                       "id_order":    e.id,
                                       "stop_loss":   tpslData.stop_loss,
                                       "take_profit": elem.target.value
                                   });
                               }}
                        />
                    </td>
                    <td>
                        <div className="d-flex justify-content-center">
                            <button className="btn"
                                    onClick={(btn) => {
                                        // setTpslData(...tpslData, tpslData.id_order = e.id);
                                        if (tpslData.id_order !== e.id || tpslData.take_profit === 0 && tpslData.stop_loss === 0) {
                                            return props.setLogs(`Нечего обновлять для #${e.id}...`);
                                        }

                                        props.setLogs(`Обновляем Loss/Profit для #${e.id}...`);
                                        Fetching({
                                            debug: false,
                                            method: 'POST',
                                            values: tpslData,
                                            request: cfg.settpsl,
                                        })
                                            .then(r => {
                                                setTimeout(() => {
                                                    document.querySelector('button[name="updateList"]').click();

                                                    if (!r || typeof r !== 'object') {
                                                        return props.setLogs('Ответ сервера: ' + JSON.stringify(r) ?? 'Ошибка, что-то пошло не так.');
                                                    }

                                                    r.status === true
                                                        ? props.setLogs(`Ордер #${e.id} успешно изменен`)
                                                        : props.setLogs(r.message ?? 'Ошибка, что-то пошло не так.');
                                                    }, 300);
                                            })
                                    }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                                </svg>
                            </button>
                        </div>
                    </td>
                    <td>
                        <div className="d-flex justify-content-center mt-1">
                            <button className="btn-close" aria-label="Close"
                                    onClick={(btn) => {
                                        props.setLogs(`Удаляем ордер #${e.id}..`)
                                        Fetching({
                                            debug: false,
                                            method: 'POST',
                                            values: {order_id : e.id},
                                            request: cfg.delOrder,
                                        })
                                            .then(r => {
                                                setTimeout(() => {
                                                    r.status === true
                                                        ? props.setLogs(`Ордер #${e.id} успешно удален`)
                                                        : props.setLogs(r.message ?? 'Ошибка, что-то пошло не так.');

                                                    btn.target.parentElement.parentElement.parentElement.remove();
                                                }, 300);
                                            })
                                    }}
                            />
                        </div>
                    </td>
                </tr>
            );
        });
        return(
            <>
                <table id="order-list-table" className="table table-striped d-block overflow-auto" style={{maxHeight: '57vh'}}>
                    <thead>
                    <tr>
                        <th scope="col">Edit</th>
                        <th scope="col">#ID</th>
                        <th scope="col">PnL</th>
                        <th scope="col">ROI</th>
                        <th scope="col" style={{minWidth: '100px'}}>Size Coins</th>
                        <th scope="col" style={{minWidth: '100px'}}>Long/Short</th>
                        <th scope="col" style={{minWidth: '160px'}}>Marning Money</th>
                        <th scope="col" style={{minWidth: '160px'}}>Marning Ration</th>
                        <th scope="col" style={{minWidth: '120px'}}>Entry Price</th>
                        <th scope="col" style={{minWidth: '120px'}}>Mark Price</th>
                        <th scope="col" className="d-block" style={{width: '100px'}}>Liq Price</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Cross</th>
                        <th scope="col">Stop loss</th>
                        <th scope="col">Take profit</th>
                        <th scope="col">Update</th>
                        <th scope="col">Remove</th>
                    </tr>
                    </thead>
                    <tbody>{list}</tbody>
                </table>

                {/* Modal - Edit order */}
                <EditOrder
                    editOrder={editOrder}
                    setLogs={props.setLogs}
                    setIsLoading={setIsLoading}
                    isBot={true}
                />
            </>
        );
    } else {
        return data;
    }
}
