import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import reportWebVitals from './reportWebVitals';
// css & scss
import "./css/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/color-modes.css"; // use after bootstrap.css
// js
import "./js/color-modes.js";
import "bootstrap/dist/js/bootstrap.min.js";

const root = ReactDOM.createRoot(document.getElementById('root'));

if (document.URL.indexOf('localhost') !== -1) {
    // local
    root.render(
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <App />
        </CookiesProvider>
    );
} else {
    // remote
    root.render(
        <React.StrictMode>
            <CookiesProvider defaultSetOptions={{ path: '/' }}>
                <App />
            </CookiesProvider>
        </React.StrictMode>
    );
}

reportWebVitals();
