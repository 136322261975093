import React from "react";

/**
 * Global Configurations.
 *
 * @type {{getAutoTasksApi: string, sendMessageTelegramUserUrl: string, getMySessionsUrl: string, getAllSessionsApiUrl: string, getTitle: (function(): JSX.Element), authWithTokenUrl: string, delMySessionUrl: string, addTaskApiUrl: string, getTasksApi: string, registrationUrl: string, addAccountApiUrl: string, switchAutoApiUrl: string, editAutoTaskUrl: string, getAuthApi: string}}
 *
 * Docs
 * Production: IP: http://82.97.246.119:8000/docs
 * Stage: IP: none
 */

const PROD   = 'http://82.97.246.119:8000';
const STAGE  = '';
const CUR_IP =  PROD;

export const cfg = {
    // Variables
    IS_GET_LISTS_BY_INTERVAL: true,
    GET_LISTS_INTERVAL:  10000, // milliseconds
    ADD_ORDER_MODAL_ID:  'addOrderModal',
    EDIT_ORDER_MODAL_ID: 'editOrderModal',
    // Base

    // Balance
    getBalance:   CUR_IP + "/api/getBalance",
    editBalance:  CUR_IP + "/api/editBalance",
    // Orders
    addOrder:     CUR_IP + "/api/addOrder",
    addOrderBot:  CUR_IP + "/api/addOrderBot",
    delOrder:     CUR_IP + "/api/delOrder",
    editOrder:    CUR_IP + "/api/editOrder",
    editOrderBot: CUR_IP + "/api/editOrderBot", // todo Не готово апи
    getOrders:    CUR_IP + "/api/getorders", // todo api опечатка
    getOrdersBot: CUR_IP + "/api/getordersbot",
    settpsl:      CUR_IP + "/api/settpsl",   // todo api опечатка
    // Methods
    getTitle: function() {
        return (CUR_IP === PROD)
            ? <span className="badge bg-danger curos-pointer">Production!</span>
            : <span className="badge text-bg-info curos-pointer">Stage 🧩</span>
    },
    getLoadingMsg: function() {
        return <h6 className="text-center">Загрузка...</h6>;
    }
}
