import React from 'react';
import {Outlet, Link, useLocation } from "react-router-dom";

const Footer = () => {
    const location = useLocation();

    return (
        <footer>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
                    <symbol id="popup-settings" width="16" height="16" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
                        <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                    </symbol>
                </svg>
                <div className="dropdown position-fixed bottom-0 end-0 mb-5 me-3">
                    <button className="btn btn-bd-primary py-2 dropdown-toggle d-flex align-items-center"
                            type="button" aria-expanded="false" data-bs-toggle="dropdown" aria-label="Settings">
                        <svg className="bi my-1" width="1em" height="1em">
                            <use href="#popup-settings"/>
                        </svg>
                        <span className="visually-hidden">Settings</span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end shadow">
                        <li>
                            {/*active - className*/}
                            <Link to="/" className="text-decoration-none">
                                <button type="button"
                                        className={
                                            "dropdown-item d-flex align-items-center" +
                                            (location.pathname === '/' ? ' active' : '')
                                        }
                                        aria-pressed="true"
                                >
                                    <svg className="bi me-2 opacity-50" width="1em" height="1em">
                                        <use href="#popup-settings"/>
                                    </svg>
                                    Binance user
                                </button>
                            </Link>
                        </li>

                        <li>
                            <Link to="/bot" className="text-decoration-none">
                                <button type="button"
                                        className={
                                            "dropdown-item d-flex align-items-center" +
                                            (location.pathname === '/bot' ? ' active' : '')
                                        }
                                        aria-pressed="true"
                                >
                                    <svg className="bi me-2 opacity-50" width="1em" height="1em">
                                        <use href="#popup-settings"/>
                                    </svg>
                                    Binance bot
                                </button>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            {/*<Outlet />*/}
        </footer>
    );
};

export default Footer;