import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {cfg} from "./cfg";
import AdminUser from "./pages/AdminUser";
import Footer from "./components/ui/Footer";
import Header from "./components/ui/Header";
import BotUser from "./pages/BotUser";

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/">
                    <Route index      element={<AdminUser/>} />
                    <Route path="bot" element={<BotUser/>} />
                </Route>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
